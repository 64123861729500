.sticky-social-icons {
    position: fixed;
    top: 85%;
    right: 10px; 
    transform: translateY(-50%);
    z-index: 1000; 
  }
  
  .social-icon {
    display: block;
    margin-bottom: 10px;
    font-size: 12px;
    text-decoration: none;
    transition: transform 0.3s ease; 
    border-radius: 70%; 
    padding: 10px; 
  }
  
  .social-icon:hover {
    transform: scale(1.2);
  }

  .social-icon.call{
    background-color: white;
    color: #1da1f2;
  }

  .social-icon.linkedin{
    background-color: aliceblue;
    color: #1da1f2;
  }
  
  .social-icon.whatsapp {
    background-color: rgb(5, 167, 5);
    color: #fff;
  }
  
  .social-icon.twitter {
    background-color: #1da1f2; 
    color: #fff; 
  }
  
  
  .social-icon.instagram {
    color: #fff;
    background: radial-gradient(circle farthest-corner at 35% 90%, #fec564, transparent 50%), radial-gradient(circle farthest-corner at 0 140%, #fec564, transparent 50%), radial-gradient(ellipse farthest-corner at 0 -25%, #5258cf, transparent 50%), radial-gradient(ellipse farthest-corner at 20% -50%, #5258cf, transparent 50%), radial-gradient(ellipse farthest-corner at 100% 0, #893dc2, transparent 50%), radial-gradient(ellipse farthest-corner at 60% -20%, #893dc2, transparent 50%), radial-gradient(ellipse farthest-corner at 100% 100%, #d9317a, transparent), linear-gradient(#6559ca, #bc318f 30%, #e33f5f 50%, #f77638 70%);
  }
  
  @keyframes blinkIcons {
    0% {
      opacity: 1;
    }
    50% {
      opacity: 0;
    }
    100% {
      opacity: 1;
    }
  }
  
  /* Animation for background */
  @keyframes blinkBackground {
    0% {
      background-color: #fff;
    }
    50% {
      background-color: #f0f0f0;
    }
    100% {
      background-color: #fff;
    }
  }

  .social-icon.instagram:hover,
  .social-icon.linkedin:hover,
  .social-icon.twitter:hover,
  .social-icon.call:hover,
  .social-icon.whatsapp:hover{
    animation: blinkIcons 1s infinite;
  }